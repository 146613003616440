<template>
    <div class="Collapseable-Menu">
        <h2 class="collapseable-menu-title" @click="toggle" v-if="this.title">
            {{ this.title }}
        </h2>
        <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
            <slot v-if="open"></slot>
        </transition>
    </div>
</template>
  
<script>
export default {
    name: 'CollapseableMenu',
    props: {
        title: String,
        name: String,
        startOpen: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            open: false
        }
    },
    mounted() {
        this.open = this.startOpen;
    },
    methods: {
        toggle() {
            this.open = !this.open;
            this.$emit("MenuDrawerToggled", this.name);
        },
        Close() {
            this.open = false;
        },
        Open() {
            this.open = true;
        },
        enter(element) {
            const width = getComputedStyle(element).width;

            element.style.width = width;
            element.style.position = "absolute";
            element.style.visibility = "hidden";
            element.style.height = "auto";

            const height = getComputedStyle(element).height;
            element.style.width = null;
            element.style.position = null;
            element.style.visibility = null;
            element.style.height = 0;

            getComputedStyle(element).height;

            requestAnimationFrame(() => {
                element.style.height = height;
            });
        },
        afterEnter(element) {
            element.style.height = "auto";
        },
        leave(element) {
            const height = getComputedStyle(element).height;

            element.style.height = height;
            getComputedStyle(element).height;

            requestAnimationFrame(() => {
                element.style.height = 0;
            });
        }
    },
    emits: ["MenuDrawerToggled", "Open", "Close"]
}
</script>
  
<style scoped>
* {
    will-change: height;
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000px;
}

.collapseable-menu-title {
    cursor: pointer;
    user-select: none;
    margin: 0;
}

.expand-enter-active,
.expand-leave-active {
    transition: height 0.4s ease-in-out;
    overflow: hidden;
}

.expand-enter,
.expand-leave-to {
    height: 0;
}
</style>