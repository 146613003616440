<template>
    <div class="Character-Reincarnation">
        <div class="header">
            <h1>Reincarnation</h1>
        </div>
        <div class="stat s">Good <span class="xs">for</span> Nothing</div>
        <div class="value">{{ this.numberWithCommas(manaRequirements.goodForNothing) }}</div>
        <div class="stat">Incompetent</div>
        <div class="value">{{ this.numberWithCommas(manaRequirements.incompetent) }}</div>
        <div class="stat">Average</div>
        <div class="value">{{ this.numberWithCommas(manaRequirements.average) }}</div>
        <div class="stat">Skilled</div>
        <div class="value">{{ this.numberWithCommas(manaRequirements.skilled) }}</div>
        <div class="stat">Distinguished</div>
        <div class="value">{{ this.numberWithCommas(manaRequirements.distinguished) }}</div>
        <div class="stat">Genius</div>
        <div class="value">{{ this.numberWithCommas(manaRequirements.genius) }}</div>
    </div>
</template>
  
<script>
export default {
    name: 'CharacterReincarnation',
    props: {
        manaRequirements: {
            type: Object,
            default() {
                return {
                    "goodForNothing": 1,
                    "incompetent": 15,
                    "average": 75,
                    "skilled": 300,
                    "distinguished": 1500,
                    "genius": 7500
                }
            }
        }
    },
    methods: {
        numberWithCommas(x) {
            if (x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            return x;
        }
    }
}
</script>
<style scoped>
.Character-Reincarnation {
    border: 2px solid var(--border);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 46px repeat(6, auto);
}

h1 {
    padding-top: 6px;
    margin: 0px;
}

.header {
    background-color: var(--body);
    grid-row: 1;
    grid-column: span 2;
    border-bottom: 2px solid var(--border);
}

.stat {
    background-color: var(--table-row-dark);
    padding: 4px;
    font-weight: 600;
}

.s {
    font-size: 0.75em;
}

.xs {
    font-size: 0.8em;
}

.value {
    padding: 4px;
    background-color: var(--table-row-light);
}
</style>
  