<template>
  <div class="Menu-Class-List">
    <CollapseableMenu class="menu-item" :id="`menu-item-${category}`" :name="category" :ref="`menu-item-${category}`" :startOpen="(index === 0)"
      :title="menuItems[category].name" v-for="(category, index) in Object.getOwnPropertyNames(menuItems)" :key="category"
      @MenuDrawerToggled="toggled">
      <div class="Menu-Class-List-Container">
        <div v-for="character in menuItems[category].items" :key="character.i" class="menuItem"
          @click="this.$emit('OnMenuClicked', character.i)">
          {{ character.n }}
        </div>
      </div>
    </CollapseableMenu>
  </div>
</template>

<script>
import CollapseableMenu from "./ui/CollapseableMenu.vue";

export default {
  name: 'MenuClassList',
  components: {
    CollapseableMenu
  },
  props: {
    menuItems: Object
  },
  methods: {
    toggled(name) {
      const menuItems = document.getElementsByClassName("menu-item");
      for (let i = 0; i < menuItems.length; i++) {
        const item = menuItems[i];
        if (item.id !== `menu-item-${name}`) {
          const menuItem = this.$refs[item.id][0];
          if (menuItem) {
            menuItem.Close();
          }
        }
      }
    }
  },
  emits: ["OnMenuClicked"],
  watch: {
    menuItems: {
      handler(newData) {
        if (this.$refs[0]) {
          this.$refs[0].Open();
        }
        console.log(newData);
      },
      flush: 'post'
    }
  }
}
</script>

<style scoped>
.Menu-Class-List {
  background-color: var(--table-row-light);
  border: 2px solid var(--border);
}

.menuItem {
  cursor: pointer;
  font-size: 1.25em;
  text-align: left;
  padding-left: 24px;
  background-color: var(--table-row-dark);
}

.menuItem:hover {
  text-decoration: underline;
}
</style>
