<template>
    <div class="Game-Menu">
        <div v-for="game in this.gamesList" :key="game.key" :id="`menu-${game.key}`" class="gameOption" @click="onClick">
            {{ game.title }} <br />
            <span class="subtitle">
                {{ game.subtitle }}
            </span>
        </div>
    </div>
</template>
  
<script>
import games from "../../assets/data/menuGamesList.json";
export default {
    name: 'GameMenu',
    props: {
        default: {
            type: String,
            default: "menu-disgaea2"
        }
    },
    data() {
        return {
            gamesList: games,
            selected: ""
        }
    },
    computed: {
        columnWidth() {
            return `${100 / this.gamesList.length}%`;
        }
    },
    mounted() {
        const el = document.getElementById(this.default);
        if (el) {
            el.classList.add("highlight");
            this.selected = this.default;
        }
    },
    methods: {
        onClick(event) {
            const clickedEl = event.srcElement;
            if (clickedEl.id !== this.selected) {
                const oldEl = document.getElementById(this.selected);
                oldEl.classList.remove("highlight");
                this.selected = clickedEl.id;
                clickedEl.classList.add("highlight");

                this.$emit("GameChanged", this.selected);
            }
        }
    },
    emits: ['GameChanged']
}
</script>
<style scoped>
.Game-Menu {
    display: flex;
    justify-content: space-between;
    border: 2px solid var(--border);
    background-color: var(--body);
}

.subtitle {
    font-size: 0.75em;
    pointer-events: none;
}

.gameOption {
    margin: 4px;
    padding: 2px 8px 2px 8px;
    border: 1px solid var(--border);
    cursor: pointer;
    width: v-bind(columnWidth);
    user-select: none;
}

.game:hover {
    background-color: var(--table-row-dark);
}

.highlight {
    color: var(--font-yellow);
    background-color: black;
    pointer-events: none;
}
</style>