<template>
  <div class="game">
    <GameMenu @game-changed="onChanged" />
    <CollapseableMenu startOpen>
      <GameDisgaea1 v-if="this.game == 'menu-disgaea1'" />
    </CollapseableMenu>
    <CollapseableMenu startOpen>
      <GameDisgaea2 v-if="this.game == 'menu-disgaea2'" />
    </CollapseableMenu>
  </div>
</template>

<script>
import GameDisgaea2 from './components/game/GameDisgaea2.vue';
import GameDisgaea1 from './components/game/GameDisgaea1.vue';
import GameMenu from './components/game/GameMenu.vue';
import CollapseableMenu from './components/ui/CollapseableMenu.vue';

export default {
  name: 'App',
  components: { GameDisgaea1, GameDisgaea2, GameMenu, CollapseableMenu },
  data() {
    return {
      game: "menu-disgaea2",
      implemented: ["menu-disgaea2"]
    }
  },
  methods: {
    onChanged(id) {
      if(this.implemented.includes(id)) {
        this.game = id;
      }
    }
  }
}
</script>

<style>
@font-face {
  font-family: OptimalTPro;
  src: url("./assets/fonts/OptimaLTPro-Roman.otf") format("opentype");
}

:root {
  --body: #D6BEA6;
  --border: #472528;
  --font-dark: #282010;
  --font-yellow: #FFFF80;
  --table-row-dark: #BEAC9C;
  --table-row-light: #CEBFAB;
}

#app {
  font-family: OptimalTPro;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--font-dark);
  margin-top: 16px;
}

.Game-Menu {
  margin-bottom: 16px;
}

.game {
  width: 1200px;
  margin: auto;
  left: 50%
}
</style>
