<template>
    <div class="Character-Traits">
        <div class="header">
            <h1>Traits</h1>
        </div>
        <div v-if="this.traits.length > 0" class="character-traits">
            <p v-for="(trait, index) in this.traits" :key="`trait-${index}`">
                {{ trait }}
            </p>
        </div>
        <div v-else class="character-traits">&nbsp;</div>
    </div>
</template>
  
<script>
export default {
    name: 'CharacterAptitudes',
    props: {
        traits: {
            type: Array,
            default: () => {
                return [];
            }
        }
    }
}
</script>
<style scoped>
.Character-Traits {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 46px auto;
    background-color: var(--body);
    border: 2px solid var(--border);
    min-height: 1px;
    text-align: left;
}

h1 {
    padding-top: 6px;
    margin: 0px;
}

.header {
    background-color: var(--body);
    grid-row: 1;
    grid-column: span 6;
    border-bottom: 2px solid var(--border);
    text-align: center;
}

.character-traits p {
    margin: 8px 2px 6px 4px;
}
</style>
  