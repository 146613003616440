<template>
    <div class="Character-Proficiencies">
        <div id="fist" class="weapon" @click="onClick('fist', ['atk', 'spd'])">
            <div class="icon"><img src="../assets/img/icon-fist.png" alt="Fists" /></div>
            <div class="amount">{{ this.proficiencies.fist }}</div>
        </div>
        <div id="sword" class="weapon" @click="onClick('sword', ['atk'])">
            <div class="icon"><img src="../assets/img/icon-sword.png" alts="Swords" /></div>
            <div class="amount">{{ this.proficiencies.sword }}</div>
        </div>
        <div id="spear" class="weapon" @click="onClick('spear', ['atk'])">
            <div class="icon"><img src="../assets/img/icon-spear.png" alt="Spears" /></div>
            <div class="amount">{{ this.proficiencies.spear }}</div>
        </div>
        <div id="bow" class="weapon" @click="onClick('bow', ['atk', 'hit'])">
            <div class="icon"><img src="../assets/img/icon-bow.png" alt="Bows" /></div>
            <div class="amount">{{ this.proficiencies.bow }}</div>
        </div>
        <div id="gun" class="weapon" @click="onClick('gun', ['hit'])">
            <div class="icon"><img src="../assets/img/icon-gun.png" alt="Guns" /></div>
            <div class="amount">{{ this.proficiencies.gun }}</div>
        </div>
        <div id="axe" class="weapon" @click="onClick('axe', ['atk'])">
            <div class="icon"><img src="../assets/img/icon-axe.png" alt="Axes" /></div>
            <div class="amount">{{ this.proficiencies.axe }}</div>
        </div>
        <div id="wand" class="weapon" @click="onClick('wand', ['atk'])">
            <div class="icon"><img src="../assets/img/icon-wand.png" alt="Wands" /></div>
            <div class="amount">{{ this.proficiencies.wand }}</div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'CharacterProficiencies',
    emits: ['OnHighlight', 'OnClearHighlight'],
    data() {
        return {}
    },
    props: {
        proficiencies: {
            type: Object,
            default() {
                return {
                    "fist": "C",
                    "sword": "C",
                    "spear": "C",
                    "bow": "C",
                    "gun": "C",
                    "axe": "C",
                    "wand": "C"
                }
            }
        }
    },
    watch: {
        proficiencies: {
            handler(newValue) {
                if (newValue.fist === "-" || newValue.fist === "M") {
                    this.clearHighlights();
                    this.$emit("OnClearHighlight");
                }
            },
            flush: 'post'
        }
    },
    methods: {
        onClick(id, stats) {
            const element = document.getElementById(id);
            if (element.classList.contains("highlight")) {
                this.clearHighlights();
                this.$emit("OnClearHighlight");
            }
            else if (this.proficiencies.fist !== "-" && this.proficiencies.fist !== "M") {
                this.clearHighlights();
                element.classList.add("highlight");
                this.$emit("OnHighlight", { type: id, stats: stats });
            }
        },
        clearHighlights() {
            document.querySelectorAll(".weapon").forEach(element => {
                element.classList.remove("highlight");
            });
        }
    }
}
</script>
<style scoped>
.Character-Proficiencies {
    min-height: 40px;
    min-width: 40px;
    background-color: var(--table-row-light);
    border: 2px solid var(--border);
    justify-items: center;
}

.weapon {
    display: inline-block;
    color: var(--font-dark);
    font-size: 1.9em;
    width: calc(100%/7);
    vertical-align: middle;
    cursor: pointer;
}

.weapon:hover {
    font-weight: 600;
}

.amount {
    padding: 4px 0 0 4px;
}

.icon>img {
    width: 30px;
    height: auto;
    vertical-align: middle;
}

.weapon>div {
    display: inline-block;
}

.icon,
.amount {
    user-select: none;
}

.highlight {
    font-weight: 600;
    color: red;
}</style>
  