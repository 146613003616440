<template>
    <div class="Game-Disgaea-2">
        <div class="classSelectMenu">
            <MenuClassList :menuItems="menuData" @OnMenuClicked="menuClicked" />
        </div>
        <div class="selectedClassDisplay">
            <ClassProfile :weaponSkillsData="weaponSkillsData" :classSkillsData="classSkillsData"
                :characterClasses="selectedCharacterClass" portraitsPath="/img/disgaea2/portraits" />
        </div>
    </div>
</template>
  
<script>
// Data Import
import menuData from "../../assets/data/disgaea2/menuClassList.json";
import weaponSkillsData from "../../assets/data/disgaea2/weaponSkills.json";
import classSkillsData from "../../assets/data/disgaea2/classSkills.json";
import characterClassData from "../../assets/data/disgaea2/classData.json";

// Component Import
import MenuClassList from '../MenuClassList.vue';
import ClassProfile from "../ClassProfile.vue";

export default {
    name: 'GameDisgaea2',
    props: {},
    components: { MenuClassList, ClassProfile },
    data() {
        return {
            menuData: menuData,
            weaponSkillsData: weaponSkillsData,
            classSkillsData: classSkillsData,
            characterClassData: characterClassData,
            selectedCharacterClass: []
        }
    },
    methods: {
        menuClicked(selectedClassId) {
            if (selectedClassId) {
                // this.$refs.classProfileWindow.selectClasses(selectedClassId);
                if (this.characterClassData[selectedClassId]) {
                    this.selectedCharacterClass = this.characterClassData[selectedClassId];
                }
            }
        }
    },
    mounted() {
        this.menuClicked("adell");
    }
}
</script>
<style scoped>
.Game-Disgaea-2 {
    display: grid;
    grid-template-columns: 224px auto;
    grid-template-rows: auto;
    gap: 16px;
    max-width: 1200px;
}
</style>
  