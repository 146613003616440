<template>
    <table class="Skills-Table" v-if="this.skills">
        <thead v-if="this.visible">
            <tr>
                <th class="name">Name</th>
                <th class="level">Lvl</th>
                <th class="stat">Stat</th>
                <th class="boost">Boost</th>
                <th class="sp">SP</th>
                <th class="height">Height</th>
                <th class="description">Description</th>
                <th class="range">Range</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(skill, index) in skills" v-bind:key="skill.name" :class="index % 2 ? 'table-dark' : 'table-light'">
                <td class="name">{{ skill.displayName }}</td>
                <td class="level">{{ skill.level }}</td>
                <td class="stat">{{ skill.attackStats }}
                    <template v-if="Array.isArray(skill.statusCss)">
                        <span v-for="(css, index) in skill.statusCss" v-bind:key="index" :class="css">
                            <p>
                                <img class="status" :src="require(`../assets/img/status-${css}.png`)" />{{
                                    skill.statusEffect[index] }}
                            </p>
                        </span>
                    </template>
                    <template v-else-if="skill.statusCss !== 'none'">
                        <span :class="skill.statusCss">
                            <p>
                                <img class="status" :src="require(`../assets/img/status-${skill.statusCss}.png`)" />{{
                                    skill.statusEffect
                                }}
                            </p>
                        </span>
                    </template>
                </td>
                <td class="boost"><span v-if="Number.isInteger(skill.boost)">{{ skill.boost }}%</span></td>
                <td class="sp">{{ skill.sp }}</td>
                <td class="height">
                    <span v-if="Number.isInteger(skill.heightUp)">
                        {{ skill.heightUp }}up
                    </span>
                    <span v-if="Number.isInteger(skill.heightUp)">
                        {{ skill.heightDn }}dn
                    </span>
                </td>
                <td class="description">{{ skill.description }}</td>
                <td class="range">
                    <SkillRangeGrid :skillPattern="skill.range" v-if="skill.range"/>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import SkillRangeGrid from "./SkillRangeGrid.vue";

export default {
    name: 'Skills-Table',
    components: { SkillRangeGrid },
    props: {
        skills: Object,
        hideHeader: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        visible() {
            return !this.hideHeader && (this.skills && this.skills.length > 0);
        }
    }
}
</script>

<style scoped>
.Skills-Table {
    border-collapse: collapse;
}

.Skills-Table p {
    margin: 1px;
}

.Skills-Table tbody {
    border: 2px solid var(--border);
}

.Skills-Table thead tr {
    color: var(--font-yellow);
    background: black;
    border: 2px solid black;
}

.Skills-Table td {
    padding-top: 4px;
    font-size: 0.8em;
    min-height: 36px;
}

.status {
    vertical-align: middle;
    width: 16px;
    height: 16px;
}

.name,
.stat {
    width: 100px;
}

.level,
.sp,
.boost,
.height {
    width: 50px;
}

.range {
    width: 64px;
}

.table-light {
    background-color: var(--table-row-light);
}

.table-dark {
    background-color: var(--table-row-dark);
}

.fire {
    color: red;
}

.wind,
.amnesia {
    color: green;
}

.ice {
    color: blue;
}

.star,
.poison {
    color: purple;
}
</style>