
<template>
    <div class="Class-Profile">
        <div class="Class-Profile-Portraits-List">
            <div class="portraits-list">
                <div v-for="characterClass in this.characterClasses" v-bind:key="characterClass.name">
                    <div class="wrapper">
                        <img class="portrait idle" :id="`class-profile-${characterClass.name}`"
                            :src="`${this.portraitsPath}/${characterClass.portrait}`"
                            @click="selectClass(characterClass, $event)" :alt="characterClass.displayName"
                            draggable="false">
                        <div class="subtitle">{{ characterClass.displayName }}</div>
                        <div class="unlock" v-if="characterClass.unlock.unlockClass !== 'none'">
                            <div>
                                Prerequisite
                            </div>
                            <div class="classInfo">
                                {{ characterClass.unlock.unlockLevel }} {{ characterClass.unlock.unlockClass }}
                            </div>
                        </div>
                        <div class="unlock" v-else>
                            <div>
                                Prerequisite
                            </div>
                            <div class="classInfo">
                                Always Available
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="traitsTable">
                <CharacterTraits :traits="this.classTraits" />
                <CharacterAptitudes ref="aptitudes" :aptitudes="this.aptitudes" :stats="this.stats"
                    :movement="this.movement" horizontal />
                <CharacterReincarnation :manaRequirements="this.reincarnation" />
            </div>
        </div>
        <div class="Class-Profile-left">
            <CharacterProficiencies @OnHighlight="this.handleHighlight" @OnClearHighlight="this.handleHighlight"
                :proficiencies="this.proficiencies" />
            <SkillsTable v-if="this.selectedClass" :skills="this.classSkills" />
            <SkillsTable v-if="this.selectedWeapon !== 'none'" :skills="this.weaponSkills" />
        </div>
        <div class="Class-Profile-right">

        </div>
    </div>
</template>
  
<script>
// UI
import CharacterAptitudes from './CharacterAptitudes.vue';
import CharacterProficiencies from './CharacterProficiencies.vue';
import CharacterReincarnation from './CharacterReincarnation.vue';
import CharacterTraits from './CharacterTraits.vue';
import SkillsTable from './SkillsTable.vue';

export default {
    name: 'ClassProfile',
    components: {
    CharacterAptitudes,
    CharacterProficiencies,
    CharacterReincarnation,
    SkillsTable,
    CharacterTraits
},
    props: {
        weaponSkillsData: Object,
        classSkillsData: Object,
        characterClasses: Array,
        portraitsPath: String
    },
    data() {
        return {
            selectedWeapon: "none",
            selectedClass: {}
        }
    },
    computed: {
        aptitudes() {
            return this.selectedClass.aptitude ? this.selectedClass.aptitude : {};
        },
        stats() {
            return this.selectedClass.stats ? this.selectedClass.stats : {};
        },
        movement() {
            return this.selectedClass.movement ? this.selectedClass.movement : {};
        },
        reincarnation() {
            return this.selectedClass.reincarnation ? this.selectedClass.reincarnation : {};
        },
        proficiencies() {
            return this.selectedClass.proficiency ? this.selectedClass.proficiency : {};
        },
        classTraits() {
            if (Array.isArray(this.characterClasses) && this.characterClasses[0]) {
                return this.characterClasses[0].traits
            }
            else {
                return [];
            }
        },
        classSkills() {
            const skills = [];
            if (this.selectedClass && Array.isArray(this.selectedClass.skills)) {
                this.selectedClass.skills.forEach(skillName => {
                    if (this.classSkillsData[skillName] == null) {
                        console.error(`Invalid skill name, ${skillName}`);
                    }
                    else {
                        skills.push(this.classSkillsData[skillName]);
                    }
                });

            }

            return skills;
        },
        weaponSkills() {
            return this.weaponSkillsData[this.selectedWeapon] ? this.weaponSkillsData[this.selectedWeapon] : [];
        }
    },
    watch: {
        characterClasses: {
            handler(characterClasses) {
                if (characterClasses && characterClasses[0] && characterClasses[0].name) {
                    this.selectClass(characterClasses[0]);
                }
            },
            flush: 'post'
        }
    },
    methods: {
        selectClass(classObj, event) {
            this.clearPortraitSelection();

            // Highlight new selection
            if (event && event.srcElement) {
                let el = event.srcElement;
                el.classList.remove("idle");
                this.selectedClass = classObj;
            }
            else if (this.characterClasses) {
                this.characterClasses.forEach(c => {
                    if (c.name == classObj.name) {
                        let el = document.getElementById(`class-profile-${c.name}`);
                        // Set data display values
                        if (el) {
                            el.classList.remove("idle");
                            this.selectedClass = c;
                        }
                    }
                });
            }
        },
        clearPortraitSelection() {
            document.querySelectorAll(".portrait").forEach(el => {
                el.classList.add("idle");
            });
        },
        handleHighlight(payload) {
            if (payload) {
                this.selectedWeapon = payload.type;
                this.$refs.aptitudes.highlightStat(payload.stats);
            }
            else {
                this.selectedWeapon = "none";
                this.$refs.aptitudes.clearHighlights();
            }
        }
    }
}
</script>
  
<style scoped>
.Class-Profile {
    display: grid;
    grid-template-columns: minmax(824px, auto);
    grid-template-rows: auto 1fr;
    gap: 16px;
}

.Class-Profile-Portraits-List {
    grid-column: 1;
    grid-row: 1;
}

.Class-Profile-left {
    grid-column: 1;
    grid-row: 2;
}

.Skills-Table {
    width: 100%;
}
.Character-Traits {
    width: 47%;
}
.Character-Aptitudes {
    width: 29%
}
.Character-Reincarnation {
    width: 20%;
}

.traitsTable {
    margin-top: 16px;
    min-height: 170px;
}

.traitsTable,
.portraits-list {
    display: flex;
    justify-content: space-between;
}

.portrait {
    width: 128px;
    height: 128px;
    border: 2px solid var(--border);
}

.wrapper {
    display: flex;
    flex-direction: column;
    max-width: 132px;
    height: 100%;
    background-color: var(--body);
}

.unlock {
    background-color: var(--body);
    border: 2px solid var(--border);
    flex-grow: 1;
}

.unlock,
.displayName {
    width: 128px;
}

.subtitle {
    margin: 0;
    padding: 4px 2px 4px 2px;
    font-weight: 600;
    color: var(--font-yellow);
    background-color: black;
}

.classInfo {
    font-size: 0.8em;
}

.idle {
    opacity: 0.65;
    border: 2px solid #CCC;
    cursor: pointer;
}
</style>