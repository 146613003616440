<template>
    <div class="skillGrid">
        <template v-for="(gridrow) in this.skillPattern">
            <div v-for="(cell, c) in gridrow" :class="`gridItem ${cell}`" :key="c"></div>
        </template>
    </div>
</template>
  
<script>
export default {
    name: 'SkillRangeGrid',
    props: {
        skillPattern: Array
    }
}
</script>
<style scoped>
.skillGrid {
    display: grid;
    grid-template-columns: repeat(7, 8px);
    grid-template-rows: repeat(7, 8px);
    user-select: none;
    width: fit-content;
    margin: auto;
}

.gridItem {
    border: 1px solid #777;
    background-color: #888;
    max-width: 8px;
    max-height: 8px;
    content: "&nbsp;";
}

.b {
    background-color: blue;
}

.g {
    background-color: green;
}

.y {
    background-color: yellow;
}

.p {
    background-color: purple;
}
</style>
  