<template>
    <div class="Character-Aptitudes">
        <div class="header">
            <h1>Aptitudes</h1>
        </div>
        <div class="stat">HP</div>
        <div class="amount">{{ this.stats.hp }}</div>
        <div class="aptitude">({{ this.aptitudes.hp }}%)</div>
        <div class="stat">SP</div>
        <div class="amount">{{ this.stats.sp }}</div>
        <div class="aptitude">({{ this.aptitudes.sp }}%)</div>
        <div class="stat">ATK</div>
        <div class="amount">{{ this.stats.atk }}</div>
        <div class="aptitude">({{ this.aptitudes.atk }}%)</div>
        <div class="stat">DEF</div>
        <div class="amount">{{ this.stats.def }}</div>
        <div class="aptitude">({{ this.aptitudes.def }}%)</div>
        <div class="stat">INT</div>
        <div class="amount">{{ this.stats.int }}</div>
        <div class="aptitude">({{ this.aptitudes.int }}%)</div>
        <div class="stat">RES</div>
        <div class="amount">{{ this.stats.res }}</div>
        <div class="aptitude">({{ this.aptitudes.res }}%)</div>
        <div class="stat">HIT</div>
        <div class="amount">{{ this.stats.hit }}</div>
        <div class="aptitude">({{ this.aptitudes.hit }}%)</div>
        <div class="stat">SPD</div>
        <div class="amount">{{ this.stats.spd }}</div>
        <div class="aptitude">({{ this.aptitudes.spd }}%)</div>
        <div class="footer">
            <div>
                <img src="../assets/img/char-counter.png" title="Counter" />
                <p class="movement">
                    {{ this.movement.counter }}
                </p>
            </div>
            <div>
                <img src="../assets/img/char-move.png" title="Move" />
                <p class="movement">
                    {{ this.movement.move }}
                </p>
            </div>
            <div>
                <img src="../assets/img/char-jump.png" title="Jump" />
                <p class="movement">
                    {{ this.movement.jump }}
                </p>
            </div>
            <div>
                <img src="../assets/img/char-throw.png" title="Throw" />
                <p class="movement">
                    {{ this.movement.throw }}
                </p>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'CharacterAptitudes',
    props: {
        aptitudes: {
            type: Object,
            default() {
                return {
                    hp: 100,
                    sp: 100,
                    atk: 100,
                    def: 100,
                    int: 100,
                    res: 100,
                    hit: 100,
                    spd: 100
                };
            }
        },
        stats: {
            type: Object,
            default() {
                return {
                    hp: 10,
                    sp: 10,
                    atk: 10,
                    def: 10,
                    int: 10,
                    res: 10,
                    hit: 10,
                    spd: 10
                };
            }
        },
        movement: {
            type: Object,
            default() {
                return {
                    counter: 0,
                    move: 3,
                    jump: 25,
                    throw: 3
                }
            }
        }
    },
    methods: {
        highlightStat(stats) {
            this.clearHighlights();
            stats.forEach((stat) => {
                document.querySelectorAll(`.${stat}`).forEach(element => {
                    element.classList.remove("highlight");
                    if (element) {
                        element.classList.add("highlight");
                    }
                });
            });
        },
        clearHighlights() {
            document.querySelectorAll(".stat").forEach(element => {
                element.classList.remove("highlight");
            });
            document.querySelectorAll(".aptitude").forEach(element => {
                element.classList.remove("highlight");
            });
            document.querySelectorAll(".amount").forEach(element => {
                element.classList.remove("highlight");
            });
        }
    },
    expose: ['highlightStat', 'clearHighlights']
}
</script>
<style scoped>
.Character-Aptitudes {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 46px repeat(5, auto);
    border: 2px solid var(--border);
}

h1 {
    padding-top: 6px;
    margin: 0px;
}

.header {
    background-color: var(--body);
    grid-row: 1;
    grid-column: span 6;
    border-bottom: 2px solid var(--border);
}

.stat {
    color: var(--font-yellow);
    background-color: black;
    min-width: 60px;
}

.aptitude {
    margin: 0 4px 0 4px;
    text-align: left;
}

.footer {
    grid-row: 6;
    grid-column: span 6;
    padding: 4px;
    border-top: 2px solid var(--border);
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    background-color: var(--body);
    font-weight: 900;
    font-size: 1.5em;
}

.footer img {
    vertical-align: middle;
    margin: 0 4px 2px 4px;
}

.footer .movement {
    display: inline-block;
}

.movement {
    margin: 0;
    margin-top: -8px;
}

.highlight {
    font-weight: 600;
    color: red;
}
</style>
  